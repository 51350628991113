import { useState, useRef } from 'react';
import { motion } from 'framer-motion';
import axios from 'axios';
import icon from "./icon.png"
export default function App() {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [calling, setCalling] = useState(false);
  const [callResponse, setCallResponse] = useState(null);
  const headerVariants = {
    hidden: {
      y: -50,
      opacity: 0
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        delay: 0.2
      }
    }
  };

  const buttonVariants = {
    hover: {
      scale: 1.01,
      textShadow: "0px 0px 2px rgb(0,0,0)",
      boxShadow: "0px 0px 2px rgb(0,0,0)",
      zIndex: 9999,
      transition: {
        duration: 0.3,
        yoyo: Infinity
      }
    }
  };

  return (
    <div className="bg-white text-gray-900">

      {/* Header */}
      <motion.header
        className="bg-white text-gray-800 p-4 flex items-center z-0"
        initial="hidden"
        animate="visible"
        variants={headerVariants}
      >
        <div className="container mx-auto max-w-4xl flex justify-between items-center">
          <h1 className="text-3xl font-bold flex">CampaignAI </h1>

          <nav>

          </nav>
        </div>
      </motion.header>

      {/* Hero */}
      <section className="relative overflow-hidden z-0">
        <div className="container mx-auto mt-24 max-w-4xl z-50">
          <motion.div
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 1 }}
          >
            <h2 className="text-5xl font-bold mb-6 flex"><span className="my-auto">AI-Powered Political Outreach</span> </h2>
            <img className="w-48 h-48 rounded-full mx-auto my-8 " src={icon}></img>
            <p className="text-2xl mb-10">Engage voters automatically with personalized and natural calls. Schedule, automate and track results.</p>
          </motion.div>

          <motion.button
            className="bg-white text-blue-500 cursor-pointer z-[900000] hover:bg-blue-600 hover:text-white px-8 py-4 rounded-full text-xl"
            variants={buttonVariants}
            whileHover="hover"
          >
            Get Started
          </motion.button>
        </div>

        {/* Waves */}
        <svg
          className="-mt-96 bottom-0 left-0 "
          viewBox="0 0 1440 363"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <motion.path
            zIndex={0}
            initial={{ pathLength: 0, pathOffset: 1 }}
            animate={{ pathLength: 1, pathOffset: 0 }}
            transition={{ duration: 1 }}
            d="M1440 27.4774C1352.73 19.8184 1122.41 49.0556 899.331 227.276C620.48 450.052 354.282 355.647 170.328 185.318C23.165 49.0556 -4.21721 8.32998 0.487081 5"
            stroke="#D1D5DB"
            strokeWidth="10"
          />
        </svg>

      </section>
      <section className="py-24">
        <div className="container mx-auto max-w-4xl flex flex-col items-center">

          <h2 className="text-5xl font-bold mb-4">Get a Demo Call</h2>

          <input
            className="border border-gray-800 text-gray-500 focus:outline-none p-2 w-1/2 my-12"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter your phone number"
          />

          <button
            className="bg-white duration-500 text-gray-900 focus:outline-none hover:text-white border border-gray-500 px-4 py-2 rounded-full hover:bg-blue-600"
            onClick={async () => {
              setCalling(true);

              try {
                await axios.post('/api/call', {
                  phoneNumber: phoneNumber
                });

              } catch (error) {
                console.error(error);
                // Handle the error in a user-friendly way
              }

              setCalling(false);
              // setCallResponse(response);
            }}
          >
            {calling ? 'Connecting...' : 'Get AI Call'}
          </button>

          {callResponse && (
            <p className="mt-4">
              {callResponse.status === 'success'
                ? 'Call successful'
                : 'Call failed'
              }
            </p>
          )}

        </div>
      </section>
      {/* Features */}
      <section className="md:py-20 md:px-0 px-4">

        <div className="container mx-auto max-w-4xl">

          <h2 className="text-4xl font-bold mb-16">
            Powerful and Flexible for Every Campaign
          </h2>

          <div className="grid md:grid-cols-2 gap-8">

            <div>
              <h3 className="text-2xl font-bold mb-4">Use Any Voice</h3>
              <p className="text-lg">
                Choose from dozens of lifelike voice models including celebrities, politicians, and local supporters.
              </p>
            </div>

            <div>
              <h3 className="text-2xl font-bold mb-4">Personalized Messaging</h3>
              <p className="text-lg">
                Craft dynamic scripts with candidate names, policy stances, and locale references to resonate.
              </p>
            </div>

            <div>
              <h3 className="text-2xl font-bold mb-4">Detailed Analytics</h3>
              <p className="text-lg">
                View engagement metrics, listen to recordings, and optimize outreach strategy.
              </p>

            </div>

            <div>
              <h3 className="text-2xl font-bold mb-4">Full Compliance</h3>
              <p className="text-lg">
                We handle TCPA consent, call disclosures, honor opt-outs, and provide transparent reporting.
              </p>
            </div>

          </div>

        </div>
      </section>

      {/* Call to Action */}
      <section className="bg-gray-900 py-16 text-center">
        <div className="container mx-auto max-w-4xl">
          <h2 className="text-5xl font-bold mb-6">Ready to Win?</h2>
          <p className="text-2xl mb-10">Get started today and take your outreach to the next level with CampaignAI.</p>
          <button className="bg-blue-500 hover:bg-blue-600 px-8 py-4 rounded-full text-xl " onClick={() => { window.open("https://dev.belva.ai/waitlist") }}>
            Start My Campaign
          </button>
        </div>
      </section>

    </div>
  );
}